.jModalImage .jModal-content-wrap {
  max-height: 95%;
  width: 95%;
}

.jModalImage .jModal-img-content {
  overflow: hidden;
}

.jModal-img-cover,
.jModal-img-contain {
  position: absolute;
  width: 100%;
}

.jModal-img-cover {
  z-index: 1;
  filter: blur(2px) grayscale(100%);
  transform: scale(1.5);
}

.jModal-img-contain {
  z-index: 2;
}

.btn-sign-in,
.btn-sign-in:link,
.btn-sign-in:visited {
  align-items: center;
  background-color: #fff;
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, .1);
  color: #6d6d6d;
  display: inline-grid;
  font-size: 14px;
  font-weight: bold;
  grid-gap: 0.5em;
  grid-template-columns: 2em 1fr;
  height: 3em;
  padding: 0 0.5em;
  margin-right: 0.5em;
}

.btn-sign-in:hover,
.btn-sign-in:focus {
  border: 1px solid rgba(0, 0, 0, .2);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, .1),
  0 0 0 2px #c6dafb;
}

.btn-sign-in:active {
  background-color: #eee;
}

.btn-sign-in .icon-facebook {
  color: #3C5A99;
}

.btn-sign-in :first-child {
  background: #fff;
}

.g-sign-in {
  background-image: url("https://cdn.joejiko.com/img/vendor/google/sign-in/web/1x/btn_google_signin_light_normal_web.png");
  display: inline-block;
  height: 46px;
  width: 191px;
}

.g-sign-in:hover,
.g-sign-in:focus {
  background-image: url("https://cdn.joejiko.com/img/vendor/google/sign-in/web/1x/btn_google_signin_light_focus_web.png");
}

.g-sign-in:active {
  background-image: url("https://cdn.joejiko.com/img/vendor/google/sign-in/web/1x/btn_google_signin_light_pressed_web.png");
}

.alert {
  padding: 0.75rem 1.25rem;
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid rgb(200, 200, 200);
}

.alert-warning .alert-header {
  background-color: #d9dcf0;
  color: #212a5e;
  margin: -0.75rem -1.25rem;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.d-grid-icon {
  align-items: center;
  grid-template-columns: min-content 1fr;
  grid-gap: 0.5rem;
}

.col-end {
  grid-column-end: -1;
}

.sticky {
  position: sticky;
}

.sticky-50 {
  top: 50px;
}

#content-header-actions {
  display: grid;
  grid-area: content-header-actions;
  grid-template-columns: repeat(auto-fit, minmax(120px, 0.5fr));
  grid-gap: 1rem;
  max-height: 50px;
}

.no-content-sidebar #main {
  grid-template-areas: "content-header" "content" "content-footer";
  grid-template-columns: 1fr;
}

.game-col:hover .game-img {
  bottom: auto;
  top: 0;
}

.d-game-img {
  transform: scale(0.9);
  z-index: 10;
}

.games-list {
  padding-top: 15px;
}

.games-list .game {
  position: relative;
  grid-column: span 2;
  display: grid;
  align-content: center;
}

.game-title {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background: #8135FF;
  color: #efefef;
  z-index: 36;
  padding: 3px 6px;
}

.game-status {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
  background-repeat: no-repeat;
  z-index: 37;
  background-image: url(https://cdn.joejiko.com/img/gaming/status-sprite-2.png);
}

.game-status[data-status="Playing"] {
  background-position: 0 0;
}

.game-status[data-status="Backlog"] {
  background-position: 0 -42px;
}

.game-status[data-status="Completed"] {
  background-position: 0 -84px;
}

.game-status[data-status="Dropped"] {
  background-position: 0 -126px;
}

.game-status[data-status="Wishlist"] {
  background-position: 0 -168px;
}

.game-status[data-status="Playing"] span,
.game-status[data-status="Backlog"] span,
.game-status[data-status="Completed"] span,
.game-status[data-status="Dropped"] span,
.game-status[data-status="Wishlist"] span {
  display: none;
}

.bg-game-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: grayscale(0.5);
  z-index: 0;
  opacity: 0.8;
}

.bg-cover-center {
  background-size: cover;
  background-position: center center;
}

.card-title {
  font-weight: 600;
}

.grid-card {
  display: grid;
}

.grid-card .card-body {
  display: grid;
}

.grid-card .card-body *:not(.card-title) {
  align-self: end;
}
