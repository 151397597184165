.ads {
  width: 100%;
}

#content-header .ads,
#content .ads,
#content-footer .ads {
  height: 6rem;
}

#sidebar .ads,
#sidebar-alt .ads,
#content-sidebar .ads,
#content-sidebar-alt .ads {
  min-height: 38rem;
}

#sidebar .sidebar-ads {
  grid-column-start: span 2;
}

.ads-test {
  box-shadow: 0 0 0 2px #222 inset;
  background-color: #eee;
}
