@import '../bootstrap4/mixins/breakpoints';

@include media-breakpoint-only(xs) {
  #app {
    grid-template-columns: 1fr;
    grid-template-rows: 40px auto auto auto;
    grid-template-areas: "header" "sidebar" "main" "footer";
  }

  .menu-default,
  .nav-group-links,
  .nav-group-actions {
    grid-template-rows: 40px;
  }

  .header-fixed {
    width: 100%;
    left: 0;
  }

  #header {
    grid-template-columns: 1fr;
    grid-template-areas: "nav-links nav-actions" "nav-actions nav-actions";
  }

  .nav-group-links {
    grid-template-columns: repeat(2, 1fr);
  }

  .menu-default .nav-link {
    text-align: center;
  }

  .nav-group-actions {
    grid-template-columns: 2fr 1fr 1fr;
  }

  #main {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "content-header" "content" "content-sidebar" "content-footer";
  }

  #sidebar {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "sidebar-content" "sidebar-footer";
  }

  #sidebar-alt {
    display: none;
  }

  #footer {
    grid-template-columns: 1fr;
    grid-template-areas: "footer-lead" "footer-social";
    grid-auto-rows: min-content;
    grid-row-gap: 1rem;

    .lead .footer-link {
      text-align: center;
    }

    .social {
      justify-content: space-between;
      grid-template-columns: repeat(auto-fit, 32px);
    }
  }

  .phpdebugbar {
    display: none;
  }
}

@include media-breakpoint-between(sm, lg) {
  #app {
    grid-template-columns: 10% auto;
    grid-template-rows: 40px auto 40px;
    grid-template-areas: "sidebar header" "sidebar main" "sidebar footer";
  }

  .header-fixed {
    width: 100%;
    left: 0;
  }

  #brand {
    grid-template-areas: "brand-kanji" "brand-icon";
    grid-template-columns: 1fr;
    justify-content: end;
  }

  .brand-kanji {
    grid-template-columns: 1fr;
  }

  #sidebar-alt {
    display: none;
  }

  .footer .social {
    grid-column-gap: .5rem;
  }
}
