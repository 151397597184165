.agent-mobile {
  #app {
    grid-template-areas: none;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    overflow-x: hidden;
  }

  #bubble {
    display: none;
  }

  #main {
    grid-template-columns: 100% !important;
    grid-template-areas: none;
    grid-row: unset;
    grid-column: unset;
    max-width: 100%;
    width: 100%;
  }

  #content-header-actions {
    grid-template-columns: 1fr;
    max-height: unset;
  }

  .menu-default {
    grid-template-areas: "nav-links" "nav-actions" "nav-extended";
    grid-template-rows: 40px 40px max-content;
  }
}
