.jModal .gaming-networks {
  grid-template-columns: repeat(auto-fill, minmax(60px, 10%));
  margin: -20px -32px;
  padding-right: 32px;
}

#content-sidebar .gaming-networks {
  grid-template-columns: repeat(auto-fill, minmax(60px, 20%));
}

.gaming-networks {
  display: grid;
  max-width: 100%;
}

.gaming-network-id,
.gaming-network-img {
  padding: 1rem;
}

.img-responsive svg {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.gaming-network-id {
  grid-column-end: span 3;
  word-break: break-all;
}

#app[data-route="gaming"] .gaming-network-img {
  background: #000000e6;
}

.gaming-network-img {
  background: #1f0c3fe6;
  text-align: right;
  grid-column-end: span 2;

  .img-psn #path-1 {
    fill: #fff !important;
  }

  .img-wiiu #_56607472,
  .img-wiiu #_56607936,
  .img-wiiu #_56605848,
  .img-wiiu #_56605920,
  .img-wiiu #_56604928 {
    fill: #fff !important;
  }
}

.img-battlenet {
  g > g path {
    fill: none;
  }

  rect {
    fill: transparent;
  }
}

.img-3ds {
  svg {
    min-height: 30px;
  }

  path:nth-child(n+2):nth-child(-n+11) {
    fill: #fff;
  }
}

.img-origin #\#6d6e70ff path {
  fill: #fff;
}

.game-activity-link {
  background-color: #222;
  color: #eee;
  &:hover {
    color: #fff;
  }
}
