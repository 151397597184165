// Google fonts
@import url("//fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800");
@import url("//fonts.googleapis.com/css?family=Lato:300,400,700,900");
// Icons
@import url("//cdn.joejiko.com/vendor/jam-icons/css/jam.min.css");
@import url("//cdn.joejiko.com/vendor/lineawesome/css/line-awesome.min.css");
@import url("//cdn.joejiko.com/vendor/fontawesome/v5.5.0/css/all.min.css");

.jam-2x {
  font-size: 2em;
}