.menu-default .nav-link {
  position: relative;
  line-height: 2.5;
  padding: 0 15px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;

  &:hover {
    color: rgba(255, 255, 255, 0.95);
  }
}

.nav-group-links {
  .nav-link:nth-of-type(1) {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .nav-link:nth-of-type(2) {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .nav-link:nth-of-type(3) {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .nav-link:nth-of-type(4) {
    background-color: rgba(0, 0, 0, 0.75);
  }
}

.nav-default-actions-contact {
  background-color: rgba(0, 0, 0, 0.7);
  text-align: right;
}

.nav-default-actions-expand {
  background-color: rgba(0, 0, 0, 0.3);
}

.nav-default-user,
.nav-default-expand {
  padding: 0 15px;
}

.menu-default {
  grid-template-rows: 40px 1fr;
}

.nav-default-links-ama {
  .question {
    border: 1px solid #ccc;
    padding: .5rem;
  }

  .question-link {
    display: grid;
    grid-template-columns: auto 32px;
    grid-gap: 8px;
  }
}

.nav-default-links-blog .categories {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
}

.nav-default-links-gaming {
  .recent-games {
    //column-count: 3;
    column-count: auto;
    column-width: 128px;
    max-width: 100%;
    overflow: hidden;
  }

  .recent-game {
    display: inline-block;
    margin-bottom: 0.75rem;
    width: 100%;
  }

  .game-img {
    width: 100%;
  }
}

.menu-default .extended-content {
  grid-template-columns: auto;
  grid-area: auto;
}

.nav-default-actions-user {
  .extended-content {
    grid-column-gap: 0;
    grid-column-start: 3;
    padding: 0;
  }

  .log-in {
    grid-column-start: 1;
  }
}

.guest .nav-default-actions-user {
  grid-template-rows: min-content;
}

.nav-default-actions-main .extended-content {
  display: grid;
  grid-column-gap: 0;
  grid-column-start: 3;
  padding: 0;
}
