// 3-1-0
html,
body {
  background-color: transparent;
  height: auto;
}

a {
  color: #495692;
}

.header-fixed {
  width: 100%;
  left: unset;
  right: unset;
  display: grid;
  grid-template-areas: "header-sidebar header header-sidebar-alt";
  grid-template-columns: auto minmax(960px, 65%) 25%;

  #header {
    position: relative;
  }

  .header {
    grid-area: header;
  }

  .sidebar {
    position: relative;

    &:first-of-type {
      grid-area: header-sidebar;
    }
  }

  .sidebar-alt {
    grid-area: header-sidebar-alt;
  }

  .sidebar-header-content {
    background-color: rgba(0, 0, 0, .95);
    height: 43px;
    position: absolute;
    top: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    grid-template-rows: 43px auto;
    grid-template-areas: "sidebar-content" ".";
  }
}

.sidebars-fixed {
  display: grid;
  grid-template-areas: "fixed-sidebar . fixed-sidebar-alt";
  grid-template-columns: auto minmax(960px, 65%) 25%;
  grid-template-rows: auto;

  .sidebar {
    display: grid;
    grid-area: fixed-sidebar;
    grid-template-columns: 100%;
    grid-template-rows: 43px auto 40px;
    grid-template-areas: "sidebar-header" "sidebar-content" "sidebar-footer";
    position: relative;
  }

  .sidebar-alt {
    grid-area: fixed-sidebar-alt;
    background-color: #d9d9d9;
  }

  .sidebar-content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-left: 0.44vmin;
  }
}

.background-fixed,
.sidebars-fixed {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.background-fixed {
  background: #eee url(//cdn.joejiko.com/img/artwork/header-at-desk.png) top left no-repeat;
  background-position-x: -360px;
  background-position-y: 43px;
  z-index: -1;
}

#bubble {
  margin-left: 0;
}

#home-header {
  background-color: rgba(255, 255, 255, .9);
}

.sidebar-link {
  padding: 0 1rem 0.125rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
  align-items: center;
  grid-gap: 1rem;
  height: 100%;
}

.sidebar-link:first-of-type {
  border-right: 1px solid #aaacb3;
  box-shadow: inset -6px 0 0 rgba(34, 34, 34, 0.1);
}

.sidebar-link:first-of-type:not(:last-of-type) {
  box-shadow: inset 6px 0 0 rgba(34, 34, 34, 0.1);
}

.sidebar-link:last-of-type:not(:first-of-type) {
  box-shadow: inset -6px 0 0 rgba(34, 34, 34, 0.1);
}

.sidebar-alt .sidebar-link:first-of-type {
  border-left: 1px solid #aaacb3;
  box-shadow: inset 6px 0 0 rgba(34, 34, 34, 0.1);
}

.sidebar-link:first-of-type:hover,
.sidebar-link:last-of-type:hover {
  box-shadow: none;
}

.sidebar-alt .sidebar-link:first-of-type:not(:last-of-type) {
  box-shadow: inset -6px 0 0 rgba(34, 34, 34, 0.1);
}

.sidebar-alt .sidebar-link:last-of-type:not(:first-of-type) {
  box-shadow: inset 6px 0 0 rgba(34, 34, 34, 0.1);
}

#blog .post:last-of-type .post-footer {
  border-bottom: none;
}

.footer-fixed {
  width: 100%;
  left: unset;
  right: unset;

  .footer {
    display: grid;
    grid-template-areas: "footer-sidebar footer footer-sidebar-alt";
    grid-template-columns: auto minmax(960px, 65%) 25%;
    grid-template-rows: 43px;
  }

  .footer-body {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-areas: "footer-lead footer-social";
    border: 1px solid #fff;
    border-top: 1px solid #aaacb3;
    background-color: #eee;
    padding: 0 15px;
    grid-area: footer;
  }

  .sidebar {
    display: grid;
    grid-template-rows: 40px;
    position: relative;
    align-self: end;
  }

  .sidebar-content {
    position: absolute;
    bottom: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    grid-template-rows: 100%;
    width: 100%;
    height: 100%;
  }
}

.sidebar:first-of-type .sidebar-link {
  background-color: #26104c54;
}

.sidebar .sidebar-link:hover {
  color: #eee;
  background-color: #26104c;
}

.sidebar:first-of-type .sidebar-link {
  justify-content: end;
}

.footer-fixed .sidebar:first-of-type {
  grid-area: footer-sidebar;
  border-top: 3px solid #eee;
}

.footer-fixed .sidebar:last-of-type {
  grid-area: footer-sidebar-alt;
  border-top: 3px solid #d9d9d9;
}

.footer-fixed .lead .footer-link {
  display: grid;
  align-items: center;
  grid-column-gap: 1rem;
  grid-template-columns: 32px auto;
}

.footer-fixed .social {
  display: grid;
  align-content: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-template-columns: repeat(auto-fit, 32px);
  justify-content: end;
}

.footer-fixed .sidebar-link {
  // background-color: #aaacb3;
  // background-color: #26104c;
  color: unset;
  background-color: #26104c54;
}

#brand {
  position: absolute;
  display: grid;
  top: 150%;
  grid-template-areas: "brand-icon" "brand-kanji";
  grid-template-columns: minmax(150px, 40%);
}

.page-home #brand {
  bottom: 100%;
  top: unset;
}

//
// 3-1-1
//

.jModal.danger {
  background-color: #ff000080;
}

.danger .jModal-content {
  background-color: #560c16;
  border-color: #560c16;
  box-shadow: 0 1px 1px #910316;
}

.danger h1 {
  color: #fefefe;
}

.danger .errors {
  background-color: #fefefe;
  color: #560c16;
}

#app {
  background-color: transparent;
  color: #130826;
  overflow-y: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 75%;
  grid-template-columns: auto minmax(1140px, 86.7%);
  grid-template-areas: ". ." ". main" ". .";
}

#main {
  background-color: transparent;
  padding-bottom: 0;
  grid-template-columns: 1fr !important;
  display: grid;
  grid-template-areas: unset;
  width: 100%;
  overflow: hidden;
  grid-template-rows: 100%;
}

#main-content {
  width: 100%;
  display: grid;
  height: 100%;
  grid-template-areas: "." "content-body" ".";
  grid-template-rows: 48px auto min-content;
  grid-template-columns: 100%;
  position: relative;
}

#content {
  display: grid;
  grid-template-areas: "content-header" "content-body" "content-footer";
  background-color: rgba(255, 255, 255, 0.9);
  overflow-y: auto;
  overflow-x: hidden;
  // background: #eee;
  grid-template-rows: auto min-content;
}

#content-body {
  display: grid;
  grid-area: content-body;
  grid-template-areas: "content content-sidebar";
  grid-template-columns: 58.33% auto;
  // background-color: #eeee;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}

#content-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 48px;
}

#content-header {
  background-color: transparent;
  position: fixed;
  width: inherit;
  min-width: 1140px;
  max-width: 65%;
}

#content-header-actions {
  background-color: rgba(255, 255, 255, .5);
}

.post {
  background: #fefefe;
}

#content-sidebar {
  overflow: hidden;
  display: grid;
  grid-template-rows: 100%;
}

.social-panels {
  grid-gap: 1vmin;
  grid-template-columns: unset;
  position: unset;
  top: unset;
  grid-template-rows: min-content auto;
}

.social-panel-main {
  display: grid;
  grid-template-rows: min-content auto;
  overflow: hidden;
}

.social-panel .uiScrollableAreaContent,
.sidebar-activities {
  display: grid;
  grid-template-rows: 100%;
  position: relative;
}

.activity-source {
  border-bottom: .125rem solid #1a0b3329;
  border-left: .125rem solid #1a0b3329;
  box-shadow: -1px 1px inset #1a0b3329;
}

.gaming-social-panel .social-panel-main {
  grid-template-rows: auto min-content;
  grid-template-areas: "a" "b";
}

.gaming-social-panel .activities-widget-header {
  grid-area: b;
}

.gaming-social-panel .uiScrollableAreaContent {
  grid-area: a;
}

.social-panel {
  grid-template-rows: min-content auto min-content;
}

.social-panel .activity-media {
  border-left: 0.125rem solid #1a0b3329;
}

.uiScrollableAreaContent {
  height: 100%;
}

.uiScrollableAreaContent .activities {
  display: grid;
  grid-template-rows: min-content auto min-content;
  position: absolute;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.social-panel-header.blue {
  background: rgb(25, 12, 86);
  background: linear-gradient(90deg, rgba(25, 12, 86, 1) 0%, rgba(30, 15, 105, 1) 100%);
}

.sidebar-activities {
  padding-left: 0.44vmin;
}

.sidebars-fixed .activity-source {
  background-color: #28194354;
  border: none;
  box-shadow: 3px 0 inset #1a0b3329;
}

::-webkit-scrollbar-track {
  // box-shadow: -1px 0 0 0 inset #26104c54;
}

::-webkit-scrollbar-thumb {
  background: #495692;
}

::-webkit-scrollbar-button {
  height: 0;
  background: unset;
}

#content::-webkit-scrollbar-thumb {
  background: transparent;
  transition: 1s background;
}

#content:hover::-webkit-scrollbar-thumb {
  background: #495692;
}

.nav-extended .ama {
  background-color: rgba(0, 0, 0, 0.6);
}

.nav-extended .media {
  background-color: rgba(0, 0, 0, 0.64);
}

.nav-extended .gaming {
  background-color: rgba(0, 0, 0, 0.68);
}

.nav-extended .blog {
  background-color: rgba(0, 0, 0, 0.72);
}

.extended-content .p-3 {
  background-color: rgba(255, 255, 255, .95);
}

.extended-footer {
  background-color: white;
}

.menu-default .nav-extended {
  position: relative;
  height: 100%;
  grid-template-rows: 100%;
}

.menu-default {
  grid-template-rows: 40px auto;
}

.nav-extended-section {
  height: 100%;
}

.extended-footer {
  background-color: white;
  border: 1px solid #dee2e6;
}

.InstagramImageActivity .bg-cover-center {
  background-position: center -100%;
}

.page-home #content {
  margin-top: 13vmin;
}
