.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.grid-fill,
.grid-fill-8 {
  grid-template-columns: repeat(auto-fill, minmax(12.5%, 1fr)) !important;

  .grid-col-full {
    grid-column: span 8 !important;
  }
}

.grid-fill-2 {
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr)) !important;
}

.grid-fill-3 {
  grid-template-columns: repeat(auto-fill, minmax(33.33%, 1fr)) !important;
}

.grid-fill-4,
.override-4 > .grid-fill {
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)) !important;
}

.grid-fill-5 {
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr)) !important;
}

.grid-fill-6 {
  grid-template-columns: repeat(auto-fill, minmax(16.66%, 1fr)) !important;
}

.grid-fill-7 {
  grid-template-columns: repeat(auto-fill, minmax(14.286%, 1fr)) !important;
}

.grid-fill-12 {
  grid-template-columns: repeat(auto-fill, minmax(8.3333%, 1fr)) !important;
}

.grid-fit,
.grid-fit-8 {
  grid-template-columns: repeat(auto-fit, minmax(12.5%, 1fr)) !important;
}

.grid-col-2 {
  grid-column: span 2 !important;
}

.grid-col-3 {
  grid-column: span 3 !important;
}

.grid-col-4 {
  grid-column: span 4 !important;
}

.grid-col-5 {
  grid-column: span 5 !important;
}

.grid-col-6 {
  grid-column: span 6 !important;
}

.grid-col-7 {
  grid-column: span 7 !important;
}

.grid-col-8 {
  grid-column: span 8 !important;
}

.grid-col-9 {
  grid-column: span 9 !important;
}

.grid-col-10 {
  grid-column: span 10 !important;
}

.grid-col-11 {
  grid-column: span 11 !important;
}

.grid-col-12 {
  grid-column: span 12 !important;
}

.gap-2 {
  grid-gap: 0.5rem;
}
