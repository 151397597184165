#app[data-route="ama"],
#app[data-route="answer-with-slug"] {
  .question-link {
    color: #eee;
  }

  .question-link:hover {
    color: #fff;
  }

  .question {
    display: grid;
    grid-area: question-container;
    grid-template-areas: "question-header" "question-response-text" "question-time-data";
    border-color: #eee #ccc #ccc #eee;
    border-style: solid;
    border-width: 1px 1px 2px;
  }

  .question-header {
    display: grid;
    grid-area: question-header;
    grid-template-areas: "question-info" "question-text";
    padding: .5rem;
    background-color: #26104c;
    color: #eee;
  }

  .question-text {
    display: grid;
    grid-area: question-text;
    padding: 1rem 2rem;
  }

  .question-info {
    display: grid;
    grid-area: question-info;
    grid-template-areas: "question-id question-asked-by question-asked-datetime";
    grid-template-columns: min-content 1fr max-content;
    grid-gap: .5rem;
    background: url(https://cdn.joejiko.com/img/pages/questions/circle-q.png) no-repeat scroll left center transparent;
    padding-left: 2rem;
  }

  .question-id {
    grid-area: question-id;
    margin: 0;
  }

  .question-asked-by {
    color: rgba(255, 255, 255, 0.4);
    grid-area: question-asked-by;
    margin: 0;
  }

  .question-info .time-data-date {
    grid-area: question-asked-datetime;
  }

  .question .response-text {
    grid-area: question-response-text;
    background: url(https://cdn.joejiko.com/img/pages/questions/circle-a.png) no-repeat scroll 5px 15px transparent;
    padding-left: 2.5rem;
    padding-top: 1rem;
  }

  .question .time-data {
    grid-area: question-time-data;
    display: grid;
    grid-template-columns: repeat(2, min-content 1fr);
    font-size: 0.8rem;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    background-color: #eee;
    min-width: 25rem;
    max-width: 100%;
    width: 50%;
    margin-left: auto;
  }

  .question .time-data-label {
    padding: .5rem;
  }

  .time-data .time-data-date {
    color: #222;
    padding: .5rem;
  }
}

textarea.expand {
  padding: 0;
  resize: none;
}

body.dialog {
  overflow: hidden;
}

.askForm .question {
  background: #fff;
  border: none;
  padding: 10px;
  position: relative;
  margin-bottom: 0;
}

.askForm .question textarea {
  max-height: 600px;
  min-height: 20px;
  line-height: 20px;
  height: 20px;
  width: 100%;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #2b3237;
  display: block;
  outline: 0;
}

.ask-question {
  height: 7rem;
  line-height: 4;
  width: 100%;
}

.btn-ask {
  border: 1px solid #000;
  cursor: pointer;
  font-size: 2rem;
}

.question-stats {
  font-size: 15px;
}

.question-stats .stat {
  display: inline-block;
  color: rgba(0, 0, 0, .4);
  width: 49%;
  text-align: center;
  vertical-align: top;
}

.question-stats .stat-text {
  font-size: 24px;
}

.answered .stat-text {
  color: rgba(0, 255, 0, .7);
}

.waiting .stat-text {
  color: rgba(0, 0, 255, .7);
}

.rejected .stat-text {
  color: rgba(255, 0, 0, .7);
}

.btn-toggle-mini {
  position: absolute;
  visibility: hidden;
  z-index: -1;
  top: 0px;
  left: 0px;
}

.btn-toggle-mini + label {
  position: relative;
  display: block;
  padding-left: 40px;
  color: #8b8b94;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  cursor: pointer;
  transition: color .2s ease-in-out;
  margin-right: 150px;
}

.btn-toggle-mini + label:only-of-type {
  display: inline-block;
  margin-right: 0;
}

.btn-toggle-mini + label::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  vertical-align: middle;
  height: 15px;
  line-height: 15px;
  width: 30px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.15);
  transition: background .2s ease-in-out;
}

.btn-toggle-mini + label::after {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  content: '';
  margin: 0;
  position: absolute;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  top: 2px;
  left: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, .9);
  padding: 0;
  transition: left .2s ease-in-out, background .2s ease-in-out;
}

.btn-toggle-mini:disabled + label::after {
  font-family: "modernpics";
  content: '';
  text-align: center;
  font-size: 6px;
  line-height: 11px;
  box-sizing: border-box;
}

.btn-toggle-mini + label span {
  line-height: 18px;
}

.optionsBar-counter {
  background: none;
  min-width: 26px;
  text-align: right;
  width: auto;
  font-size: 12px;
  padding-top: 1px;
  color: rgba(0, 0, 0, 0.3);
}

.optionsBar-buttonWrap {
  float: right;
  clear: right;
}

.ask-tab {
  display: inline-block;
  position: relative;
  padding-left: 21px;
  margin-top: 10px;
  padding-bottom: 5px;
}

.ask-tab .title {
  display: inline-block;
  font-weight: 600;
}

.up-arrow {
  display: block;
  border-bottom: 12px solid #ebedf0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  bottom: -10px;
  font-size: 0;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  width: 0;
}

.up-arrow::after {
  border-bottom: 12px solid #fff;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  content: '';
  left: -12px;
  position: absolute;
  top: 1px;
}

.ask-header {
  background-color: #f5f6f7;
  border-bottom: 1px solid #dddfe2;
  border-radius: 2px 2px 0 0;
  font-weight: bold;
}

#ask .dismiss {
  display: none;
}

#ask.askSection--focused .dismiss {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  padding: 0 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, .4);
}

.optionsBar {
  background-color: #f5f6f7;
  height: 50px;
  border-top: 2px solid #dddfe2;
  border-radius: 0 0 2px 2px;
  padding: 10px;
}

#ask {
  margin: 10px 10px 20px;
  border: 1px solid #dddfe2;
  border-radius: 2px;
}

#ask .presentation {
  display: none;
  opacity: 0;
}

#ask.askSection--focused .ask-header,
#ask.askSection--focused .askForm {
  position: relative;
  z-index: 9000;
}

#ask.askSection--focused .presentation {
  display: block;
  background: #000;
  transition: opacity .3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  z-index: 8999;
}

#btnAskSend {
  min-width: 5rem;
  max-width: 100px;
  padding-left: .5rem;
  padding-right: .5rem;
  margin-left: 1rem;
  line-height: 1;
}

.legacy #app[data-route="ama"],
.legacy {
  .question {
    background: none repeat scroll 0 0 #FFF;
    border-color: #EEE #CCC #CCC #EEE;
    border-style: solid;
    border-width: 1px 1px 2px;
    margin-bottom: 5px;
    padding: 0;
    position: relative;
    width: 100%;
  }

  .question-info {
    background: url(https://cdn.joejiko.com/img/pages/questions/circle-q.png) no-repeat scroll left center transparent;
    height: 26px;
    left: 5px;
    line-height: 1;
    padding-left: 30px;
    position: absolute;
    top: 5px;
  }

  .question-id {
    display: inline-block;
    margin: 0;
  }

  .question-text, .question-link {
    color: #000000;
    display: block;
    font-size: 25px;
    line-height: 1.25em;
    margin: 0;
    padding: 1.75rem 1rem 0.75rem 2rem;
  }

  .question-asked-by {
    color: rgba(0, 0, 0, 0.4);
    display: inline-block;
    margin: 0;
  }

  .response-text {
    background: url(https://cdn.joejiko.com/img/pages/questions/circle-a.png) no-repeat scroll 5px 15px transparent;
    line-height: 1.5;
    margin: 0;
    padding: 10px 5px 20px 40px;
  }

  .time-data {
    background-color: rgba(0, 0, 0, .05);
  }

  .time-data-label {
    font-size: 15px;
    width: 12.5%;
    background-color: rgba(0, 0, 0, .05);
    text-align: center;
  }

  .time-data-date {
    width: 12.5%;
    text-align: center;
  }

  .date {
    border-top: 1px dotted;
    color: #999999;
    font-size: 10px;
    line-height: 1;
    margin: 0;
    padding: 2px 10px;
    text-align: right;
  }

  .box {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
  }
}
