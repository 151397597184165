[data-route-name="home"] #sidebar .sidebar-content {
  align-self: end;
}

#app[data-route="v2:home"] {
  #header {
    width: 20%;
  }

  #main,
  #navigation {
    width: 65%
  }

  #main {
    margin: 0 15% 0 20%;
  }

  #brand-position {
    position: absolute;
    right: 15px;
    bottom: 15px;
    top: auto;
  }

  #Logo {
    background-color: rgba(238, 238, 238, .9);
  }

  #content-footer {
    width: 65%;
  }
}

#bubble {
  background: url("//cdn.joejiko.com/img/shared/long-bubble.svg") no-repeat scroll 0 0 / contain rgba(0, 0, 0, 0);
  height: 140px;
  line-height: 3;
  margin-left: -14%;
  margin-top: 0;
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 976px;
  z-index: 902;
  transform: rotate(3deg);
}

#entries-latest .entry {
  border-bottom: 3px solid #eee;
}

#entries-latest .row:last-of-type .entry {
  border: none;
}

#blog {
  .post img {
    height: auto;
    max-width: 100% !important;
  }
}

#blog .post-category {
  color: #8135ff;
  border-top: 3px solid #8135ff;
  background-color: transparent;
  border-bottom: 0;
}

#blog .post-keywords .btn-link {
  font-size: 0.8rem;
  background-color: #eee;
}

.home-jumbotron {
  margin-bottom: 0;
}

.panel-shadowless {
  box-shadow: none;
}

.social-panels {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  grid-row-gap: 1rem;
  border-left: .5rem solid #eee;
  position: sticky;
  top: 50px;
}

#home-content .social-panels {
  border-left: .5rem solid #eee;
  border-bottom: 15px solid #eee;
}

.social-panel-header .text-muted,
.social-panel-header .text-muted .text-link {
  color: #26104c !important;
}

.social-panel-header h2 {
  color: #200d40;
}

#app[data-route="home"] #main {
  grid-template-columns: 58.33% auto;
}

.social-panel {
  display: grid;
  grid-template-rows: min-content auto min-content;
}

.social-panel-header {
  display: grid;
  border-bottom: 1px solid #1a0b33;
  padding: 15px;
}
.social-panel-header.blue {
  background-color: #26104c;
  border-top: 3px solid #2e3192;
}
.social-panel-header.purple {
  background-color: #5a25b2;
  border-top: 3px solid #8135ff;

}

.social-panel-footer {
  //border-bottom: 1rem solid #eee;
  background: #fff;
}

.social-panel .instagram-photos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.nav-home-intro {
  display: grid;
  grid-area: nav-home-intro;
  grid-template-columns: repeat(auto-fit, minmax(120px, .5fr));
  grid-gap: 1rem;
}

#home-header .lead {
  padding: 1rem;
}

@media (min-width: 1440px) and (max-width: 1440px) {
  #bubble {
    line-height: 3.5;
    margin-top: 90px;
    height: 145px;
  }
}

@media (min-width: 1600px) and (max-width: 1600px) {
  #bubble {
    line-height: 4;
    margin-top: 75px;
    height: 161px;
  }
}

@include media-breakpoint-between(lg, xxll) {
  #bubble {
    line-height: 3.5;
    margin-top: 50px;
    height: 140px;
    text-align: left;
    text-indent: 7rem;
    margin-left: -7%;
  }
}
