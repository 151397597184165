#main {
  z-index: 90;
}

#sidebar {
  z-index: 50;
}

#sidebar-alt {
  z-index: 40;
}
