.nav-link {
  .icon-external-link-square {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: none;
    align-items: center;
    width: 10%;
    min-width: 1rem;
    justify-content: center;

    &:hover {
      background-color: rgba(255, 255, 255, .6);
    }
  }
}

[data-menu-active="true"] > .icon-external-link-square {
  display: grid;
}

.nav-extended {
  grid-template-columns: repeat(4, 1fr);
  //background-color: #8135ff;
  background-color: rgba(0, 0, 0, .5);
  overflow-y: auto;
}

.extended-container {
  display: grid;
  grid-template-areas: "nav-extended-content" "nav-extended-footer";
  grid-template-rows: auto min-content;
  //grid-template-rows: 1fr minmax(100px, max-content);
  //grid-gap: 1rem;
  height: 100%;
  position: relative;
  overflow: hidden;
  //grid-template-columns: repeat(2, 35%) auto;
  grid-template-columns: 1fr;
}

.extended-content {
  display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  grid-column-gap: 1rem;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  //grid-template-rows: 1fr;
  grid-template-rows: 100%;
  padding: 1rem;
  position: absolute;
}

.extended-footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, .5fr));
  grid-gap: 1rem;
}

.nav-extended-section {
  display: none;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .6);
  height: 90%;
  overflow: hidden;
  width: 100%;
  position: absolute;
}

.extended-footer .btn {
  border-top: 1px solid #ccc;
  border-radius: unset;

  &:hover {
    background-color: #d9d9d9;
  }
}

.extended-content > * {
  max-height: 100%;
}

.js-menu--extended,
.js-menu--extended > :not([data-role="menu"]),
.js-menu--extended [data-role="menu"] {
  height: 100% !important;
  max-height: 900px;
}

.js-menu--extended {
  .extended-section--extended {
    display: block;
  }
}
