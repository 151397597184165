.post-category-link,
.sidebar-header-link,
.menu-default .nav-link,
img[data-role="modal"],
a img {
  transition: opacity 0.2s linear;
}

.post-category-link:hover,
.sidebar-header-link:hover,
.menu-default .nav-link:hover,
a:hover img,
img[data-role="modal"]:hover {
  opacity: .8;
}
