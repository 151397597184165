button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.jModal--active {
  overflow: hidden;
}

.jModal {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9900;

  .container {
    max-width: 100% !important;
  }

  .modal-header {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.jModal-positioning {
  height: 100%;
  position: relative;
}

.jModal-content-wrap {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 80%;
  /*overflow: hidden;*/
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  z-index: 1;
}

.jModal-cta {
  position: absolute;
  z-index: 200;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 100%;
  display: grid;
  //grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(10vw, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(25%, 1fr));
  align-content: end;
  align-items: center;
  //direction: rtl;

  :first-child {
    grid-column-start: -1;
  }
}

.jModal-close {
  //background-color: #ff0000;
  //box-shadow: 0 -1px 0 #c80000 inset;
  background-color: rgba(0, 0, 0, .9);
  cursor: pointer;
  //  height: 20px;
  padding: 17px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  /*width: 30px;*/
  z-index: 300;

  .close {
    color: rgba(255, 255, 255, .9);
    display: inline-block;
    height: 11px;
    line-height: 1;
    width: 11px;
    font-size: 17px;
  }
}

.jModal-close-message {
  display: none;
}

.jModal-content {
  background-color: #fff;
  border: 1px solid #000;
  box-shadow: 0 1px 1px;
  height: auto;
  max-height: 100%;
  min-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 32px;
  transition: all 1s ease 0s;
  width: 100%;
}

.jModal-mask {
  //background-color: rgba(0, 0, 0, .2);
  background-color: #1f0c3fe6;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.form-compact .row {
  margin-left: -5px;
  margin-right: -5px;
}

.form-compact .col-md-1,
.form-compact .col-md-2,
.form-compact .col-md-3,
.form-compact .col-md-4,
.form-compact .col-md-5,
.form-compact .col-md-6,
.form-compact .col-md-7,
.form-compact .col-md-8,
.form-compact .col-md-9,
.form-compact .col-md-10,
.form-compact .col-md-11,
.form-compact .col-md-12 {
  padding-left: 5px;
  padding-right: 5px;
}

.jModal .loading {
  display: none;
  text-align: center;
  padding: 5% 0;
}

#L7 {
  max-width: 80%;
  max-height: 80%;
  path {
    fill: rgba(50, 50, 50, .5);
  }
}

.jModal--loading {
  .loading {
    display: block;
    // url(//cdn.joejiko.com/img/shared/loading/loading.gif)
    //background: none no-repeat scroll center 30% rgba(255, 255, 255, .8);
    background: none rgba(255, 255, 255, .8);
    height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .loading-message {
    background: rgba(255, 255, 255, .5);
    font-weight: bold;
    left: 0;
    padding: .5em;
    position: absolute;
    text-align: center;
    text-transform: capitalize;
    top: 32%;
    width: 100%;
  }
}

img[data-role=modal] {
  cursor: zoom-in;
}

.jModalImage {
  .jModal-content {
    height: 100%;
  }

  .jModal-img-content {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
  }

  .jModal-cta .btn {
    color: #fff;
  }

  .jModal-cta :first-child {
    grid-column-start: 8;
  }

  .jModal-cta :nth-child(2) {
    grid-column-start: 4;
    grid-row-start: 3;
  }

  .jModal-cta :nth-child(3) {
    grid-column-start: 6;
    grid-row-start: 3;
  }
}
