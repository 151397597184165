@import '../bootstrap4/mixins/breakpoints';

@include media-breakpoint-between(xs, xl) {
  #app {
    grid-template-columns: auto minmax(960px, 65%) minmax(25%, auto);
  }

  .header-fixed,
  .footer-fixed {
    left: unset;
    right: 25%;
  }

  .sidebar-alt-fixed {
    width: 25%;
  }
}

@include media-breakpoint-between(xl, xxll) {
  #app {
    grid-template-columns: 10% 65% 25%;
  }

  .header-fixed,
  .footer-fixed {
    left: 10%;
  }

  .sidebar-fixed {
    width: 10%;
  }

  .sidebar-alt-fixed {
    width: 25%;
  }
}
