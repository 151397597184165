#app {
  display: grid;
  grid-template-columns: 20% 65% 15%;
  grid-template-rows: 40px auto 40px;
  grid-template-areas: "sidebar header sidebar-alt" "sidebar main sidebar-alt" "sidebar footer sidebar-alt";
}

.menu-default {
  grid-area: header;
  display: grid;
  grid-template-columns: 70% auto;
  grid-template-areas: "nav-links nav-actions" "nav-extended nav-extended";
  z-index: 100;

  .nav-group-links {
    grid-area: nav-links;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .nav-group-actions {
    grid-area: nav-actions;
    display: grid;
    grid-template-areas: "nav-contact nav-user nav-admin nav-expand";
    grid-template-columns: 1fr auto auto auto;
    grid-template-rows: 36px;
  }

  .nav-default-links-ama {
    .questions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
    }

    .question {
      display: grid;
      grid-template-rows: auto min-content;
    }

    .extended-content {
      grid-template-columns: 1fr;
    }
  }
}

#sidebar {
  display: grid;
  grid-area: sidebar;
  grid-template-columns: 100%;
  grid-template-rows: 43px auto 40px;
  grid-template-areas: "sidebar-header" "sidebar-content" "sidebar-footer";

  .sidebar-content {
    display: grid;
    align-self: start;
  }
}

#brand {
  display: grid;
  grid-template-areas: "brand-icon" "brand-kanji";
  grid-template-columns: minmax(150px, 40%);
  justify-content: end;
}

.brand-kanji {
  display: grid;
  grid-template-columns: repeat(3, 50px);
  justify-content: center;
}

#main {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: min-content auto min-content;
  grid-template-areas: "content-header content-header" "content content-sidebar" "content-footer content-footer";
}

#main.no-sidebar {
  grid-template-columns: 100%;
  grid-template-rows: auto 40px;
  grid-template-areas: "content" "content-footer";
}

#sidebar-alt {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 43px auto 40px;
  grid-template-areas: "sidebar-header" "sidebar-content" "sidebar-footer";
}

#footer {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-areas: "footer-lead footer-social";

  .lead .footer-link {
    display: grid;
    align-items: center;
    grid-column-gap: 1rem;
    grid-template-columns: 32px auto;
  }

  .social {
    display: grid;
    align-content: center;
    align-items: center;
    grid-column-gap: 8px;
    grid-template-columns: repeat(auto-fit, 32px);
    justify-content: end;
  }
}

.content-col-3 #main {
  grid-template-columns: 15% 70% 15%;
  grid-template-areas: "content-header content-header content-header" "content-sidebar-alt content content-sidebar" "content-footer content-footer content-footer";
}
