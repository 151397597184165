$discord-blurple: #7289da;
$discord-greyple: #99aab5;
$discord-white: #fff;
$discord-dark: #2c2f33;
$discord-black: #232721;

$twitch-purple: #6441a4;

.discord-link {
  background-color: $discord-blurple;
  color: $discord-white;
  width: 100%;
  padding: .5rem;

  &:hover {
    color: #fff;
  }

  .icon-discord {
    color: #fff;
  }
}

.errantnights {
  background-color: #222;
}

.errantnights-link {
  background-color: #222;
  color: #fff;
  width: 100%;
  padding: .5rem;

  &:hover {
    color: #fff;
  }
}

.twitch-link {
  background-color: $twitch-purple;
  color: #fff;

  &:hover {
    color: #fff;
  }
}
