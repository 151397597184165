#blog {
  .post-category-link,
  .post-keywords .btn-link {
    color: #26104c;
  }

  .posts img {
    max-width: 100%;
    height: auto;
  }

  .post-category {
    font-size: 24px;
  }

  .post-category-link {
    color: #8135ff;
  }

  .timestamps {
    color: rgba(0, 0, 0, 0.4);
  }

  .post-body {
    padding: 0 1rem 1rem;
  }

  .wp-block-image {
    display: block;
    text-align: center;
  }
  
  .post-body img {
    max-height: 30vh;
    width: auto;
    height: auto;
  }

  .post-footer {
    border-bottom: 1rem solid #eee;
  }
}
