@import 'bootstrap4/bootstrap';
//@import 'normalize';
@import 'fonts';
@import 'transitions';
@import 'ads';
@import 'grid';

/** base **/
a {
  color: #1693cc;
}

a,
a:hover {
  text-decoration: none;
}

a:hover {
  color: #1e38bf;
}

/** layout **/
html,
body,
#app {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 100%;
  margin: 0;
  padding: 0;
}

html, body {
  height: auto;
  background-color: transparent;
}

body {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

#app { // <body>
  background-color: #eee;
  max-height: 100%;
}

@import 'layout';
@import 'ui';
@import 'utilities';
@import 'components';
@import 'theme';
@import 'pages';

.header-fixed,
.footer-fixed,
.sidebar-fixed,
.sidebar-alt-fixed {
  position: fixed;
}

.header-fixed,
.footer-fixed {
  left: 20%;
  width: 65%;
  height: 40px;
  z-index: 200;
}

.header-fixed {
  grid-area: header-fixed;
  top: 0;
  left: 20%;
}

.footer-fixed {
  grid-area: footer-fixed;
  position: fixed;
  bottom: 0;
}

.sidebar-fixed,
.sidebar-alt-fixed {
  top: 0;
  height: 100%;
}

.sidebar-fixed {
  left: 0;
  width: 20%;
}

.sidebar-fixed #sidebar,
.sidebar-alt-fixed #sidebar-alt {
  height: 100%;
}

.sidebar-alt-fixed {
  right: 0;
  width: 15%;
}

#main {
  grid-row: 2/2;
  grid-column: 2/2;
  padding-bottom: 40px;
}

.sidebar-alt-fixed .sidebar-content {
  overflow-y: auto;
}

@import 'dev';
@import 'mobile';
@import 'breakpoints';
@import '3-1-1';
