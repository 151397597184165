.activity {
  padding: 0;
  border: 0;
  grid-template-areas: "activity-media activity-action activity-source";
  //grid-template-columns: minmax(min-content, 35%) 1fr 35px;
  grid-template-columns: minmax(min-content, .25fr) 1fr 35px;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
  }

  [class^="icon-"] {
    grid-row-start: -1;

    &:nth-of-type(2) {
      grid-row-start: -2;
    }

    &:nth-of-type(3) {
      grid-row-start: -3;
    }
  }
}

.activity.dev {
  grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));

  .activity-media {
    grid-column: span 5;

    .img-responsive {
      height: auto;
      max-width: 100%;
      width: 100%;
    }
  }

  .activity-body {
    grid-column: span 10;
  }

  .activity-action {

  }

  .activity-source {

  }
}

/* v3/pages/home/activities */
.YoutubeVideoEmbed > div,
.FacebookVideoEmbedContainer {
  padding-bottom: 56.25%; /* 16:9 */
  height: 0 !important;
  width: 100% !important;
}

.YoutubeVideoEmbed iframe,
.FacebookVideoEmbed iframe {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.YoutubeVideoCreated .activity-media > div {
  padding-bottom: 56.25%; /* 16:9 */
  height: 0 !important;
  width: 100% !important;
}

.YoutubeVideoCreated .activity-media iframe {
  width: 100% !important;
  height: 100% !important;
}

.sidebar-activities {
  //padding-left: 1rem;
  padding-left: .44vw;
}

.activities .activity {
  display: grid;
  grid-gap: 0;
}

.activity-action {
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-area: activity-action;
  padding: .5rem;
  background: rgba(255, 255, 255, 0.9);
}

.activity-media {
  grid-area: activity-media;
  display: grid;
  align-items: center;
  padding: .5rem;
  text-align: center;
  overflow: hidden;
  grid-template-rows: minmax(1vh, 10vh);
  background: rgba(255, 255, 255, .3);

  &:hover {
    background: rgba(255, 255, 255, .9);
  }
}

.activity-action-datetime {
  text-align: right;
}

.activities-widget-header {
  background: rgba(255, 255, 255, .9);
}

.activities-list {
  display: grid;
  grid-gap: .25rem;
}

.activity-source {
  grid-area: activity-source;
  grid-template-rows: repeat(auto-fill, minmax(min-content, 2vh));
  align-items: end;
  background-color: rgba(255, 255, 255, 0.3);
  display: grid;
  padding: .25rem;

  .fab {
    font-size: 2rem !important;
  }
}

.social-panel {
  .activity-media {
    border-left: .125rem solid #1a0b33;
  }

  .activity-action,
  .activity-media {
    background: rgba(255, 255, 255, .9);
  }
}

.FacebookStatusActivity {
  grid-template-columns: 0 1fr 35px;
}

.FacebookStatusActivity .activity-action-body {
  padding-left: 1rem;
  display: grid;
  align-items: center;
}

.activities i[class^="icon-facebook"] {
  background-color: rgb(60, 90, 153);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.activities i[class^="icon-instagram"] {
  background: rgb(64, 93, 230);
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.img-fill-x {
  width: 100%;
  height: auto;
  max-width: 100%;
}

a {
  color: #130826;
}

.FacebookVideoEmbedContainer {
  width: 480px;
  height: 270px;
  overflow: hidden;
  position: relative;
}

.GameActivity {
  grid-template-areas: "activity-media activity-action activity-action" "activity-source activity-action activity-action";
}

.FacebookPhotoActivity,
.FacebookVideoActivity,
.InstagramImageActivity,
.InstagramPhotoActivity {
  grid-template-areas: "activity-media activity-media activity-media" "activity-action activity-action activity-source";
}

.activity-media img,
.instagram-photos img {
  filter: grayscale(100%);
}

.activities-list:hover img,
.instagram-photos img:hover {
  filter: grayscale(0);
}

//.sidebar-content .activities-list {
//  filter: blur(2px);
//}
//
//.activities-list:hover {
//  filter: blur(0);
//}
//#blog .post-title a {
//  color: #8135ff;
//}

.GameActivity {
  .activity-source {
    grid-template-columns: repeat(2, min-content) 1fr;
    justify-content: end;
    align-items: center;
  }

  .activity-source-label {
    font-size: .8rem;
  }

  [class^="icon-"] {
    grid-row-start: unset;

    &:nth-of-type(2) {
      grid-row-start: unset;
    }
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@include media-breakpoint-between(sm, lg) {
  .activity {
    grid-template-columns: minmax(min-content, 20%) 1fr 35px;
  }

  .activity-media {
    padding: .25rem;
  }
}
