.extended-container {
  grid-template-columns: 1fr;
}

.extended-content {
  padding: 0;
  grid-column-gap: 0;
}

.menu-default {
  .extended-content {
    grid-template-columns: repeat(2, 35%) auto;
    grid-area: nav-extended-content;
  }
}

.nav-default-actions-main {
  opacity: .5;
  background: #000;

  .extended-links {
    align-items: center;
    background-color: #8135ff;
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));
    grid-template-columns: 1fr;
    grid-column-start: 3;
  }

  .la-2x {
    vertical-align: sub;
  }

  .btn {
    height: 100%;
    display: grid;
    align-items: center;

    &:hover {
      background-color: rgba(0, 0, 0, .3);
    }
  }
}

.nav-default-actions-user {
  background-color: rgba(0, 0, 0, 0.8);

  .extended-content {
    grid-template-rows: min-content;
  }

  .log-out,
  .log-in {
    grid-column-start: 3;
  }

  .log-out {
    padding: 0.5rem 1.5rem;
  }
}

.nav-default-links-ama {
  .extended-content {
    grid-template-columns: 2fr;
    grid-column-end: 3;
  }
}

.nav-default-links-gaming {
  .now-playing {
    display: grid;
    grid-template-rows: min-content auto;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }
}

.nav-default-links-gaming .platforms,
.nav-default-links-ama .questions-content,
.nav-default-links-blog .col {
  padding: 1rem 1rem 0;
}

.nav-default-links-blog {
  .categories {
    margin: 0;
    padding-left: 1rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    justify-items: start;
  }
}

@include media-breakpoint-only(xs) {
  .header-fixed {
    width: 100% !important;
    left: 0 !important;
  }
}

@include media-breakpoint-between(sm, lg) {
  .header-fixed {
    width: 100% !important;
    left: 0 !important;
  }
}
