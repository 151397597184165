@import 'layout/z-index';
@import 'layout/grid';
@import 'layout/menu';
@import 'layout/sidebars';

.brand-kanji {
  background-color: rgba(238, 238, 238, 0.9);
  color: rgba(0, 0, 0, 0.4);
  font-size: 40px;

  .ji {
    text-align: center;
  }

  .jyo {
    padding-left: 10px;
  }

  .ko {
    padding-right: 10px;
    text-align: right;
  }
}

// Layout
#brand {
  padding: 0 1rem;
}

#brand .brand-icon {
  display: inline-block;
}

#content-footer {
  line-height: 2.4;
  padding: 0 15px;
}

#footer {
  padding: 0 15px;
}

/** svg **/
#Logo {
  background-color: rgba(238, 238, 238, 0.9);
  height: auto;
  max-width: 100%;
}
