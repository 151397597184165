@import '../bootstrap4/variables';
@import '../bootstrap4/mixins/breakpoints';

// stylelint-disable declaration-no-important

// Grid variation
//
// Custom styles for additional grid alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .grid-justify-content#{$infix}-start {
      justify-content: start !important;
    }

    .grid-justify-content#{$infix}-end {
      justify-content: end !important;
    }

    .grid-justify-content#{$infix}-center {
      justify-content: center !important;
    }

    .grid-align-items#{$infix}-start {
      align-items: flex-start !important;
    }

    .grid-align-items#{$infix}-end {
      align-items: flex-end !important;
    }

    .grid-align-items#{$infix}-center {
      align-items: center !important;
    }

    .grid-align-items#{$infix}-baseline {
      align-items: baseline !important;
    }

    .grid-align-items#{$infix}-stretch {
      align-items: stretch !important;
    }

    .grid-align-content#{$infix}-start {
      align-content: flex-start !important;
    }

    .grid-align-content#{$infix}-end {
      align-content: flex-end !important;
    }

    .grid-align-content#{$infix}-center {
      align-content: center !important;
    }

    .grid-align-content#{$infix}-between {
      align-content: space-between !important;
    }

    .grid-align-content#{$infix}-around {
      align-content: space-around !important;
    }

    .grid-align-content#{$infix}-stretch {
      align-content: stretch !important;
    }

    .grid-align-self#{$infix}-auto {
      align-self: auto !important;
    }

    .grid-align-self#{$infix}-start {
      align-self: flex-start !important;
    }

    .grid-align-self#{$infix}-end {
      align-self: flex-end !important;
    }

    .grid-align-self#{$infix}-center {
      align-self: center !important;
    }

    .grid-align-self#{$infix}-baseline {
      align-self: baseline !important;
    }

    .grid-align-self#{$infix}-stretch {
      align-self: stretch !important;
    }
  }
}
