::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: #aaacb3;
}

::-webkit-scrollbar-button {
  height: 40px;
  background: #ccc;
}

.uiScrollableArea ::-webkit-scrollbar-thumb {
  background: #797b80;
}

.uiScrollableArea {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.uiScrollableAreaWrap {
  /* sneaky hide scrollbars */
  //margin-right: -16px;
  //padding-right: 30px;
  height: 100%;
  outline: none;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  padding-right: .1vw;
}

@supports (-ms-accelerator:true) {
  // Edge 12
  .uiScrollableAreaWrap {
    margin-right: -17px;
  }
}

@-moz-document url-prefix() {
  // firefox
  .uiScrollableAreaWrap {
    margin-right: -17px;
  }
}

.uiScrollableAreaBody {
  position: relative;
}
