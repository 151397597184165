#sidebar-alt .sidebar-header,
#sidebar-alt .sidebar-footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  grid-template-rows: 100%;
}

.sidebar-header-link,
.sidebar-footer-link {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
  align-items: center;
  grid-gap: 1rem;
  padding: 0 1rem;
  height: 100%;
}
