// Grid areas
.nav-default-contact {
  grid-area: nav-contact;
}

.nav-default-user {
  grid-area: nav-user;
}

.nav-default-expand {
  grid-area: nav-expand;
}

.menu-default .nav-extended {
  grid-area: nav-extended;

  .extended-content {
    //grid-area: nav-extended-content;
  }

  .extended-footer {
    grid-area: nav-extended-footer;
  }
}

#brand {
  grid-area: brand;

  .brand-icon {
    grid-area: brand-icon;
  }

  .brand-kanji {
    grid-area: brand-kanji;
  }
}

#main {
  grid-area: main;
}

#content-header {
  grid-area: content-header;
}

#content {
  grid-area: content;
}

#content-sidebar {
  grid-area: content-sidebar;
}

#content-sidebar-alt {
  grid-area: content-sidebar-alt;
}

#content-footer {
  grid-area: content-footer;
}

.sidebar-header {
  grid-area: sidebar-header;
}

.sidebar-content {
  grid-area: sidebar-content;
}

.sidebar-footer {
  grid-area: sidebar-footer;
}

#sidebar-alt {
  grid-area: sidebar-alt;
}

#footer {
  grid-area: footer;

  .lead {
    grid-area: footer-lead;
  }

  .social {
    grid-area: footer-social;
  }
}
