@import 'theme/menu';
@import 'theme/brands';

/** theme **/
#sidebar {
  background: url(//cdn.joejiko.com/img/artwork/header-at-desk.png) top right no-repeat;
  background-position-x: 57%;
}

#sidebar .sidebar-header {
  background-color: #8135ff;
}

@include media-breakpoint-between(sm, lg) {
  #sidebar {
    background-position-x: 51%;
  }
}

/** @todo apply scss math to opacity n+1 **/
#sidebar .sidebar-header-content {
  background-color: rgba(0, 0, 0, .95);
  height: 43px;
}

#header {
  background-color: #8135ff;
}

#content {
  background-color: rgba(255, 255, 255, 0.9);
}

#content-header {
  background-color: rgba(255, 255, 255, 0.5);
}

#sidebar-alt {
  background-color: #d9d9d9;
}

#footer {
  border-top: 1px solid #aaacb3;
  background-color: #eee;

  .footer-link {
    color: rgba(0, 0, 0, 0.4);

    &:hover {
      color: #1e38bf;
    }
  }
}
